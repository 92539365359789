import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import frederick_temple from '../assets/images/frederick_temple.jpg'
import deg14 from '../assets/images/14.svg'
import deg18 from '../assets/images/18.svg'
import deg30 from '../assets/images/30.svg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Frederick Scottish Rite" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>About Us</h2>
                </header>
                <p>
                  The Ancient and Accepted Scottish Rite of Freemasonry is
                  commonly known as the Scottish Rite. It is one of several
                  appendant groups of the worldwide fraternity known as
                  Freemasonry. Each Valley has up to four Scottish Rite bodies,
                  and each body confers a set of degrees. In the Southern
                  Jurisdiction these are the Lodge of Perfection (4°-14°),
                  Chapter of Rose Croix (15°-18°), Council of Kadosh (19°-30°),
                  and the Consistory (31°-32°). The Supreme Council confers the
                  33<sup>rd</sup> Degree of Sovereign Grand Inspector General.
                </p>
                <ul className="actions">
                  <li>
                    <Link to="/about" className="button">
                      Learn More
                    </Link>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={frederick_temple} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Our 3 Bodies</h2>
            </header>
            <ul className="features">
              <li>
                <span className="image symb">
                  <img src={deg14} alt="" />
                </span>
                <h3>Lodge of Perfection</h3>
                <p>4th thru 14th Degree</p>
              </li>
              <li>
                <span className="image symb">
                  <img src={deg18} alt="" />
                </span>
                <h3>Chapter of Rose Croix</h3>
                <p>15th thru 18th Degree</p>
              </li>
              <li>
                <span className="image symb">
                  <img src={deg30} alt="" />
                </span>
                <h3>Council of Kadosh</h3>
                <p>19th thru 30th Degree</p>
              </li>
            </ul>
            <footer className="major">
              {/* <ul className="actions">
                <li>
                  <Link to="/officers" className="button">
                    Our Officers
                  </Link>
                </li>
              </ul> */}
            </footer>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Calendar of Events</h2>
              <p>
                All events are at the Frederick Masonic Temple unless otherwise
                noted.
                <br />
                6816 Blentlinger Rd. Frederick, MD 21702
              </p>
            </header>
            <iframe
              title="Calendar"
              src="https://calendar.google.com/calendar/embed?src=webmaster%40columbialodge58.org&ctz=America%2FNew_York"
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
            ></iframe>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Join Us</h2>
              <p>
                Membership in the Scottish Rite is open to all 3° Masons who
                have completed their Master Mason's Catechism.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/contact" className="button special">
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
