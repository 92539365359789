import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = (props) => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy
      items={['intro', 'first', 'second', 'cta']}
      currentClassName="is-active"
      offset={-300}
    >
      <li>
        <Scroll type="id" element="intro">
          <a href="#about">About Us</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="first">
          <a href="#bodies">Our 3 Bodies</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="second">
          <a href="#cal">Calendar of Events</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="cta">
          <a href="#contact">Join Us</a>
        </Scroll>
      </li>
    </Scrollspy>
  </nav>
)

export default Nav
