import React from 'react'

import logo from '../assets/images/32nd SRSJ Eagle.svg'

const Header = (props) => (
  <header id="header" className="alt">
    <span className="logo">
      <img src={logo} alt="" />
    </span>
    <h1>Scottish Rite Valley of Frederick</h1>
    <p>
      Composed of the Lodge of Perfection, Chapter of Rose Croix,
      <br />
      and Council of Kadosh in Frederick, Maryland
    </p>
  </header>
)

export default Header
